<template>
	<div class="edu-frame">
		<edu-nav-bar :title="new_upd_title" :show="show" @showPopup="show=true" @hidePopup="show=false"></edu-nav-bar>
		<div class="content-frame">
			<div class="edu-group" style="margin: 20px auto;">
				<div class="class-form">
					<van-field v-model="leave.leaveBeginTime" required label="开始时间" :placeholder="'请选择'"
						input-align="right" right-icon="arrow" @click="showStartDateDiv" />
					<van-field v-model="leave.leaveEndTime" required label="结束时间" :placeholder="'请选择'"
						input-align="right" right-icon="arrow" @click="showEndDateDiv" />
					<van-field :readonly="leave.fstatus!=0" label-width="80px" v-model="leave.reason" label="原因"
						placeholder="请输入原因" clearable />
					<van-field label-width="80px" readonly v-model="leave.checkUserName" label="审核人" />
					<van-field label-width="80px" readonly v-model="leave.checkTime" label="审核时间" />
					<van-field label-width="80px" readonly v-model="leave.checkReason" label="审核原因" />
				</div>
			</div>
		</div>
		<div v-if="leave.fstatus==0" class="bottom-frame">
			<van-button square block type="primary" @click="save(0)">保存不提交</van-button>
			<van-button square block type="primary" @click="save(1)">保存并提交</van-button>
		</div>
		<div v-if="leave.fstatus==1" class="bottom-frame">
			<van-button square block type="primary" @click="save(0)">撤销请假</van-button>
		</div>
		<van-popup v-model="showStartDate" position="bottom">
			<van-datetime-picker type="datetime" @confirm="onConfirmStartmDate" @cancel="showStartDate=false"
				:min-date="new Date()" title="选择开始时间" />
		</van-popup>
		<van-popup v-model="showEndDate" position="bottom">
			<van-datetime-picker type="datetime" @confirm="onConfirmEndDate" @cancel="showEndDate=false"
				:min-date="new Date()" title="选择开始时间" />
		</van-popup>
	</div>
</template>
<script>
	import {
		Field,
		Button,
		DatetimePicker,
		Popup
	} from 'vant';
	import EduNavBar from "@/components/EduNavBar";
	import Tools from "@/api/Tools";
	import XEUtils from 'xe-utils'
	import LeaveApi from "@/api/LeaveApi";
	export default {
		components: {
			EduNavBar,
			VanField: Field,
			VanButton: Button,
			VanDatetimePicker: DatetimePicker,
			VanPopup: Popup
		},
		data() {
			return {
				leaveApplyId: this.$route.query.leaveApplyId,
				leave: {
					fstatus: 0
				},
				cust: Tools.getCurCust(),
				submitLoading: false,
				show: false,
				showStartDate: false,
				showEndDate: false,
				formData: {},
				new_upd_title: this.$route.query.new_upd_title
			}
		},
		name: 'login',
		methods: {
			showStartDateDiv() {
				if (this.leave.fstatus == 0) {
					this.showStartDate = true;
				}
			},
			showEndDateDiv() {
				if (this.leave.fstatus == 0) {
					this.showEndDate = true;
				}
			},
			onConfirmStartmDate(time) {
				this.leave.leaveBeginTime = XEUtils.toDateString(time, 'yyyy-MM-dd HH:mm:ss');
				this.showStartDate = false;
			},
			onConfirmEndDate(time) {
				this.leave.leaveEndTime = XEUtils.toDateString(time, 'yyyy-MM-dd HH:mm:ss');
				this.showEndDate = false;
			},
			save(fstatus) {
				this.leave.fstatus = fstatus;
				this.leave.applyUserId = this.cust.custId;
				if (this.leave.leaveBeginTime instanceof Date) {
					this.leave.leaveBeginTime = XEUtils.toDateString(this.leave.leaveBeginTime, 'yyyy-MM-dd HH:mm:ss');
				}
				if (this.leave.leaveEndTime instanceof Date) {
					this.leave.leaveEndTime = XEUtils.toDateString(this.leave.leaveEndTime, 'yyyy-MM-dd HH:mm:ss');
				}
				this.submitLoading = true
				LeaveApi.submit(this.leave).then(response => {
					this.submitLoading = false
					if (response.code === 100) {
						this.$router.push({
							name: 'qjsq',
							query: {}
						})
					} else {
						this.$dialog.alert({
							message: response.msg
						})
					}
				}).catch(() => {
					this.submitLoading = false
				});
			},
			leaveGet() {
				if (this.leaveApplyId != null) {
					LeaveApi.getById(this.leaveApplyId).then(response => {
						this.loading = false
						this.leave = response.res;
					}).catch(() => {
						this.loading = false
					})
				}
			}
		},
		mounted() {
			this.leaveGet();
		}
	}
</script>
<style scoped>
	.form-tips {
		margin-top: 5px;
		margin-bottom: 5px;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 14px;
	}

	.split-line {
		width: 90%;
		border: 0;
		height: 1px;
		background-color: #dddddd;
	}
</style>
